import axios from 'axios'
import Qs from 'qs'
import { getSessionStorage } from '@/utils/storage.js'
import {
  toLink,
  getIsBack,
  removeIsBack,
  isFunction,
  isBoolean,
  debounce
} from '@/utils/index.js'
import setting from '@/setting.js'

const url = setting.tracking.url
const trackingRouter = setting.tracking.scene
const transform =
  setting.tracking.transform ||
  function transform(routeName) {
    return routeName
  }
const hook = setting.tracking.hook || function hook() {}
// 屏幕宽高
const screenWidth = window.screen.width
const screenHeight = window.screen.height
// 当前页面路由名
var currentRouteName = ''
// 上一个页面路由名
var fromRouteName = ''

// 收集路由守卫
function trackingRouterGuards(type, to, from) {
  let routeName = transform(to.name) || ''
  // 进入路由前记录当前路由名称
  if (type == 'before') {
    currentRouteName = routeName
    fromRouteName = getIsBack() || from.name
  }

  let target = trackingRouter[type]

  if (target && target[routeName]) {
    console.log('save接口调用完毕 路由' + type)
    sendTracking(target[routeName])
  }
}

// 埋点浏览器返回初始化
function browserBackInit(routes) {
  console.log('browserBackInit', routes)
  routes = routes.map(route => {
    return {
      path: process.env.VUE_APP_ROUTER_BASE + route.path,
      name: route.name
    }
  })

  // 监听通过链接跳转后(ios 和 android)返回的
  window.addEventListener(
    'pageshow',
    function (e) {
      if (
        e.persisted ||
        (window.performance && window.performance.navigation.type == 2)
      ) {
        console.log('p pageshow')
        resolve(e.persisted)
      }
    },
    false
  )

  // 监听vue router history返回
  window.addEventListener(
    'popstate',
    function () {
      console.log('p popstate')
      resolve()
    },
    false
  )

  function resolve(isPersisted) {
    hook('back')
    debounce(() => {
      let pathname = window.location.pathname
      let target = routes.find(route => route.path == pathname)
      let params = trackingRouter['back'][target?.name]
      console.log('埋点浏览器返回 sendTracking', params)
      if (params) {
        sendTracking(params)
      }
      if (isPersisted) {
        window.location.reload()
      }
    }, 1000)()
  }
}

function transformParams(params) {
  let p = {}
  for (let key in params) {
    let val = params[key]
    if (isFunction(val)) {
      let v = val({
        name: fromRouteName
      })
      if (v == '' || v == undefined || v == null) {
        p = false
        break
      }
      if (v != 'null') {
        p[key] = v
      }
    } else {
      p[key] = val
    }
  }
  return p
}

// 发送埋点数据
function sendTracking(data) {
  let projectCode = getSessionStorage('projectCode')
  let qrCode = getSessionStorage('qrCode')

  let params = transformParams(
    Object.assign(
      {
        projectCode: projectCode,
        qrCode: qrCode,
        fromType: 0,
        screenLength: screenHeight,
        screenWidth: screenWidth
      },
      data
    )
  )

  // 取消发送
  if (isBoolean(params)) return

  //请求发送方式
  if (navigator.sendBeacon) {
    let formData = new FormData()
    for (let key in params) {
      formData.append(key, params[key])
    }
    navigator.sendBeacon(url, formData)
  } else {
    axios.post(url, Qs.stringify(params))
  }
}

//
let callbacks = []
let pending = false

// 执行所有回调函数
function flushCallbacks() {
  pending = false
  const copies = callbacks.slice(0)
  callbacks.length = 0
  let p = []
  for (let i = 0; i < copies.length; i++) {
    p.push(copies[i]())
  }
  Promise.all(p)
    .then(() => {
      console.log('save接口调用完毕', currentRouteName)
      sendTracking(trackingRouter['nextRequest'][currentRouteName])
    })
    .catch(() => {
      console.log('save接口调用失败')
    })
}

export default function tracking(Vue, options = {}) {
  // 注册一个全局自定义指令 `v-tracking`
  Vue.directive('tracking', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: function (el, binding) {
      el.addEventListener(
        binding.value.event || 'click',
        function () {
          console.log('v-tracking', binding.value)
          sendTracking(binding.value)
        },
        false
      )
    }
  })

  // 所有请求函数推入队列之后执行
  Vue.prototype.$nextRequest = function (fn) {
    if (isFunction(fn)) {
      callbacks.push(fn)
    }

    if (!pending) {
      pending = true
      // 延迟执行所有回调函数
      setTimeout(() => {
        console.log('callbacks:: ', callbacks)
        flushCallbacks()
      })
    }
  }

  Vue.prototype.$sendTracking = sendTracking

  const router = options.router
  // 浏览器返回监听初始化
  if (router) {
    browserBackInit(router.getRoutes())

    router.beforeEach((to, from, next) => {
      // 页面进入前收集
      trackingRouterGuards('before', to, from, getIsBack())
      next()
    })

    router.afterEach((to, from) => {
      // 页面进入后收集
      trackingRouterGuards('after', to, from, getIsBack())

      if (getIsBack()) {
        console.log('是返回的')
        removeIsBack()
      }

      // 拦截所有a标签跳转
      const targetNode = document.getElementById('app')
      const config = { attributes: false, childList: true, subtree: true }
      const observer = new MutationObserver(() => {
        Vue.nextTick(() => {
          let aEls = document.querySelectorAll('a')
          aEls.forEach(el => {
            el.removeEventListener('click', function () {}, false)
            el.addEventListener(
              'click',
              function (e) {
                toLink(e.target.href, e.target?.dataset?.flag || true)
                e.preventDefault()
              },
              false
            )
          })
        })
      })
      observer.observe(targetNode, config)
    })
  }
}
