<template>
  <div class="m-rule-cont">
    <h3>活动规则</h3>
<p>一、活动期间内：凡购买今麦郎软化纯净水-《三国杀》十周年专区版，扫描瓶身二维码，进入H5页面参与抽奖活动，即有机会获得《三国杀》十周年游戏礼包或电子权益一份。综合中奖率99.99%。</p>
<p>二、活动时间：即日起至2023年12月31日18：00止。</p>
<p>三、兑奖时间：即日起至2023年12月31日18：00止。</p>
<p>四、活动范围：全国（不包含香港、澳门、台湾地区）。</p>
<p>五、参与方式：微信“扫一扫”扫描瓶身标签上二维码参与活动。</p>
<p>六、活动热线：400-815-9210（活动期间周一至周五，09:00-18:00，法定节假日除外）。</p>
<p>七、活动奖励：</p>
<p>1、三国杀十周年游戏礼包：</p>
<p>三国杀十周年福利礼包：普通皮肤包，价值5元，840000个</p>
<p>三国杀十周年幸运礼包：神曹操体验1天*1，价值5元，120000个</p>
<p>三国杀十周年手气礼包：稀有灵韵石*2+手气卡*1，价值7.5元，180000个</p>
<p>三国杀十周年珍品礼包：稀有皮肤包*1，价值15元，60000个</p>
<p>领取规则：</p>
<p>消费者获得游戏礼包后，在礼包页面复制礼包兑换码，进入《三国杀》十周年专区，点击【包裹】界面中的【礼包兑换】，输入礼包兑换码即可兑换成功。</p>
<p>2、电子权益：238800000份（实际发放数量以实际参与人数为准）；电子权益内容、金额及领取使用方式请以相关平台具体规则为准。</p>
<p>八、电子权益说明及领取规则：</p>
<p>1.	【拼多多购物补贴限时领】领取流程：进入第三方权益展示页面-点击【立即领取】-点击【开】-选择页面中商品并点击-根据页面提示打开拼多多App或授权登录并进入拼多多小程序-点击【一键抢券】或【立即抢购】-进入商品页面后-点击【立即购买】或【用券购买】-选择商品类型-点击【确认】-点击【立即支付】。使用范围：拼多多小程序或拼多多APP内。权益有效期：以页面提示为准。权益价值：100~300元随机金额购物补贴组合券。使用条件：购物补贴金具体使用条件以第三方页面活动规则公示为准。（客服电话：400-672-5100）</p>
<p>2.	【樊登阅读7天免费会员】领取流程：进入第三方权益展示页面-点击【免费体验7天】-输入注册登陆樊登阅读的手机号-输入验证码-并点击【确认】-领取成功。使用范围：樊登读书App或樊登读书小程序内。权益有效期：以页面提示为准。权益价值：约合5元会员体验权益。使用条件：仅限樊登阅读新用户领取，会员具体使用条件以第三方页面活动规则公示为准。（客服电话：400-900-3117）</p>
<p>3.	【荒神记新用户升级礼包】游戏名：荒神记。领取流程：进入第三方权益展示页面-点击【下载游戏】-点击右上角【…】使用浏览器打开-苹果用户进入AppStore下载游戏/安卓用户点击立即下载apk安装包-角色达到对应等级后-游戏中点击【福利】-输入兑换码-领取兑换礼包。使用范围：荒神记手游App内。权益有效期：以页面提示为准。权益价值：约合30元游戏礼包。使用条件：游戏礼包具体使用条件以第三方页面活动规则公示为准。（客服电话：020-38100135或联系微信公众号“冰橙游戏”联系在线客服。）</p>
<p>4.	【淘宝购物补贴限时领取】领取流程：进入第三方权益展示页面-点击【开心收下】-选择页面中的商品-点击【打开淘宝App可领】复制口令-打开淘宝App-弹出产品弹窗后-点击【查看详情】-点击【立即领券】-点击【领券购买】-选择商品种类-点击【领券购买】-点击【提交订单】-点击【立即付款】。使用范围：淘宝App内。权益有效期：以页面提示为准。权益价值：20~200元随机购物补贴组合优惠券。使用条件：购物补贴金具体使用条件以第三方页面活动规则公示为准。（客服电话：400-672-5100）</p>
<p>5.	【京东限时优惠券30元】领取流程：进入第三方权益展示页面-选择购物商品-点击【马上抢】-点击【立即购买】-选择商品类型-点击【确认】-确认订单信息后-点击【微信支付】。使用范围：京东商城H5页活动面内。权益有效期：以页面提示为准。权益价值：30元京东限时优惠补贴券。使用条件：领取成功后，限时2小时内可用。优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：400-606-5500）</p>
<p>6.	【同程旅行出行礼包】领取流程：进入第三方权益展示页面-点击【允许】授权地理位置-根据页面提示领取对应代金券并点击【立即使用】。使用范围：同程旅行H5页面或小程序内。权益有效期：以页面提示为准。权益价值：2~40元随机优惠券。使用条件：优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：95711；优惠券查看方式：微信内点击【我的】-点击【服务】-点击【火车票机票】或微信搜索同程旅行小程序-点击【我的】-点击【红包优惠券】查看。部分代金券指定资源可用，详情查看代金券使用说明。）</p>
<p>7.	【滴滴代驾券礼包免费领】领取流程：进入第三方权益展示页面-输入手机号-点击【立即领取】。使用范围：滴滴出行App内。权益有效期：以页面提示为准。权益价值：3~12元随机滴滴代驾券。使用条件：活动期间仅限新老用户领取1次。优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：400-766-6998；查看方式：打开滴滴出行App-点击【我的】-点击【钱包】查看。）</p>
<p>8.	【联通王卡百款APP专属流量】领取流程：进入第三方权益展示页面-填写领卡信息（姓名、联系电话、收货地址、详细地址、身份证号）-点击【免费领取】，开卡激活并首充50元后-微信关注【123号卡助手】-点击【1~30元话费红包】-输入下单手机号、验证码点击【立即领取】-获得1~30元不等额随机话费充值。使用范围：联通王卡活动页面内。权益有效期：以页面提示为准。权益价值：1~30元不等额随机话费。使用条件：年满16周岁用户可申领，月租费29元、国内语音通话每分钟0.1元，话费领取条件：开卡激活2月内需首充50元及以上。具体使用条件以第三方页面活动规则公示为准。（点击【免费领取】后，领取成功的部分地区用户可根据页面提示选择免费靓号（若未选择靓号，系统将自动为您随机分配号码）-点击【我选好了】-完成预订靓号。客服电话：010-67323890；退订方式：微信搜索中国联通营业厅小程序-点击【我的订单】-输入手机号码、验证码、身份证号码后六位-点击【立即查询】-选择【取消订单】-点击【申请退单】-选择退单类型-点击【提交】。）</p>
<p>9.	【闪送优惠券礼包】领取流程：进入第三方权益展示页面-输入手机号码-点击【获取验证码】-输入验证码-点击【确认】-点击【立即下单】。使用范围：闪送App或闪送小程序内。权益有效期：以页面提示为准。权益价值：老用户包含3张9.5折券（60元内可用）、新用户包含2张9折券（50元内可用）。使用条件：优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：400-612-6688）</p>
<p>10.	【饿了么外卖补贴天天领】领取流程：进入第三方权益展示页面-点击【好】授权地理位置-点击【马上登录】-点击【好】获取当前地址-输入手机号和验证码并点击【我已阅读】-点击【同意协议并登录】-点击【开心收下】领取外卖优惠券-点击【去使用】。使用范围：饿了么App或饿了么H5页面内（指定商品可用）。权益有效期：以页面提示为准。权益价值：0.1~20元随机外卖红包。使用条件：外卖补贴具体使用条件以第三方页面活动规则公示为准。（客服电话：1010-5757）</p>
<p>11.	【Keep领取7天免费会员】领取流程：进入第三方权益展示页面-点击【领取7天免费会员】-点击右上方【...】-点击使用浏览器打开-点击【领取7天免费会员】-允许打开“Keep”-再次点击【领取7天免费会员】（授权成功后到期自动续费，若到期不希望续费可提前取消，不影响7天免费会员的使用）。使用范围：Keep App内。权益有效期：以页面提示为准。权益价值：约合5元Keep7天会员体验。使用条件：仅限Keep新用户领取1次，会员具体使用条件以第三方页面活动规则公示为准。（客服电话：400-632-1666；退订流程：打开Keep App-点击【我的】-点击【我的会员】-点击【管理自动续费】-关闭自动续费服务。）</p>
<p>12.	【返利网购物补贴限时领】领取流程【页面版】：进入第三方权益展示页面-点击【开】-点击【允许】打开爱淘生活优品小程序-点击立即领取-选择购物商品-点击【马上抢】-选择商品类型-点击【立即购买】-点击【提交订单】。使用范围：爱淘生活优品小程序内。权益有效期：以页面提示为准。权益价值：20~200元随机购物补贴组合优惠。使用条件：优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：400-672-5100；订单查看方式：下单成功后可根据短信内链接提示或微信小程序搜索爱淘生活优品小程序内小程序进行订单查询、退换货等。）</p>
<p>13.	【返利网购物补贴限时领】领取流程【微信卡包版】：进入第三方权益展示页面-点击【领取到卡包】-点击【立即使用】进入爱淘生活优品小程序-选择购物商品-点击【马上抢】-选择商品类型-点击【立即购买】-点击【提交订单】。使用范围：爱淘生活优品小程序内。权益有效期：以页面提示为准。权益价值：20~200元随机购物补贴组合优惠。使用条件：优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：400-672-5100；查看订单方式：下单成功后可根据短信内链接提示或微信小程序搜索爱淘生活优品小程序内进行订单查询、退换货等。）</p>
<p>14.	【倍省权益会员专享特权】领取流程：进入第三方权益展示页面-输入手机号-点击【立即抢购】-确认支付。使用范围：倍省权益H5页面/倍省权益公众号内。权益有效期：以页面提示为准。权益价值：充值19.9元返48元话费，每月最低充值30元可抵扣4元话费，分12个月返还共计返还话费48元。使用条件：19.9元领48元话费按月抵扣券，支持（联通、移动、电信）充值，携号转网和虚拟运营商号段不支持，充值后搜索倍省权益公众号充值并领取。使用条件：具体使用条件以第三方页面活动规则公示为准。（客服电话：021-60562933）</p>
<p>15.	【美团优选新人专享福利】领取流程：进入第三方权益展示页面-点击【立即抢购】-点击【打开】打开美团优选-选择页面中商品-点击【加入购物车】-点击【去支付】-确认订单信息后-点击【立即支付】-点击【确认并支付】。使用范围：美团优选小程序内。权益有效期：以页面提示为准。权益价值：美团优选新人福利专区内根据所选商品不同约1~10元优惠券。使用条件：仅限美团优选新用户，优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：400-091-9699）</p>
<p>16.	【美团外卖红包天天领】领取流程：进入第三方权益展示页面-点击【一键领取】-点击【微信一键授权】-点击【一键领取】-点击【用券下单】。使用范围：美团外卖App内。权益有效期：以页面提示为准。权益价值：0.1~10元随机美团外卖优惠券。使用条件：优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：1010-9777）</p>
<p>17.	【滴滴打车折扣优惠券】领取流程：进入第三方权益展示页面-点击【确认】或【允许】-获取当前地理位置-点击【立即领取】-输入手机号-点击【同意协议并进行下一步】-输入验证码-领取完成。使用范围：滴滴出行小程序或滴滴出行App内。权益有效期：以页面提示为准。权益价值：约合50元滴滴出行组合优惠券。使用条件：优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：400-000-0999；优惠券查看方式：打开滴滴出行小程序或滴滴出行APP-点击【我的】-点击【优惠券】查看 。）</p>
<p>18.	【高德打车优惠券包】领取流程：进入第三方权益展示页面-点击【立即领取】-点击【允许】打开高德小程序-点击【点击领取】-点击【立即打车】。使用范围：高德地图App或高德打车小程序内。权益有效期：以页面提示为准。权益价值：100元高德打车组合优惠券，新用户立减10元。使用条件：优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：400-880-2252；优惠券查看方式：打开高德打车小程序-点击左下角【我的卡券】或打开高德地图App-点击【首页】-点击【打车】-点击滚动提示框-点击【我的卡券】查看。）</p>
<p>19.	【花小猪打车礼包】领取流程：第三方权益展示页面-点击【确定】获取你的地理位置-点击【免费领取】-输入手机号-点击【同意】-点击【下一步】-输入验证码-点击【去打车】-点击【打开】进入花小猪打车小程序-阅读个人信息处理规则并点击【同意】-领取成功。 （每天限领取一次，若优惠券未核销或未过期则无法领取新优惠券。）使用范围：花小猪小程序内。权益有效期：以页面提示为准。权益价值：约合100元打车优惠组合券。使用条件：优惠券具体使用条件以第三方页面活动规则公示为准。（在线客服：花小猪小程序内-我的-联系客服；优惠券查看方式：打开花小猪小程序或花小猪打车APP-点击左上角【我的】-点击【券】-查询领取优惠券。）</p>
<p>20.	【顺丰速运寄件立减券】领取流程：进入第三方权益展示页面-点击【立即查看】-输入手机号-点击【获取验证码】-输入验证码-点击【我同意】-点击【立即领取专属福利】。使用范围：顺丰速运App内。权益有效期：以页面提示为准。权益价值：顺丰2~15元随机优惠券。使用条件：优惠券具体使用条件以第三方页面活动规则公示为准。（客服电话：95338；优惠券查看方式：微信搜索顺丰速运微信公众号-点击【我】-点击【会员中心】-点击【优惠券】查看。）</p>
<p>21.	【泰康防疫出行险免费领】领取流程：进入第三方权益展示页面-输入（手机号、姓名、身份证号）-点击【下一步】-申领成功（该页面为用户已领取免费赠险，根据页面提示操作可以升级为付费险，若不愿升级可关闭当前页面，不影响免费赠险的领取。）使用范围：泰康赠险H5页面内。权益有效期：以页面提示为准。权益价值：700~1400元抗疫津贴+出行保障。使用条件：领取人需满足年龄18-70周岁。（客服电话：020-37889192）</p>
<p>22.	【返利网购物优惠限时领】领取流程【淘口令版】：进入第三方权益展示页面-点击【开心收下】-选择页面中的商品-点击【打开淘宝App可领】复制口令-打开淘宝App-弹出产品弹窗后-点击【查看详情】-点击【立即领券】-点击【领券购买】-选择商品种类-点击【领券购买】-点击【提交订单】-点击【立即付款】。使用范围：淘宝App内。权益有效期：以页面提示为准。权益价值：20~200元随机购物补贴组合优惠券。使用条件：购物补贴金具体使用条件以第三方页面活动规则公示为准。（客服电话：400-672-5100）</p>
<p>23.	【返利网购物优惠限时领】领取流程【补贴版】：进入第三方权益展示页面-点击【开】-点击【开】-选择购物商品并点击-点击【马上抢】-输入收货地址后点击【提交订单】。使用范围：购物优惠H5页面内。权益有效期：以页面提示为准。权益价值：20~200元随机购物补贴组合优惠券。权益价值：20~200元随机购物补贴组合优惠券。使用条件：购物补贴金具体使用条件以第三方页面活动规则公示为准。（客服电话：400-672-5100；订单查看方式：下单成功后可点击短信内链接进入商品页面-点击【我的】-【我的订单】可查看物流信息或退换货。）</p>
<p>24.	【御剑封神新手礼包】领取流程：进入第三方权益展示页面-点击【下载游戏】-点击右上角【...】使用浏览器打开-点击【下载游戏】苹果用户进入AppStore下载游戏/安卓用户点击【立即下载apk安装包】-进入游戏后点击游戏界面右下角-点击【设置】-点击【兑换】-输入礼包码-点击【提交】。使用范围：御剑封神游戏内。权益有效期：以页面提示为准。权益价值：约合30元游戏礼包。使用条件：游戏礼包具体使用条件以第三方页面活动规则公示为准。（客服电话：0755-86574319或微信搜索公众号【闲闲互娱】联系在线客服。）</p>
<p>25.	【巧虎早教礼包免费领取】 领取流程：进入第三方权益展示页面--输入（家长姓名、宝宝生日、详细地址、手机号码、是否有二宝）点击-【我已阅读并同意】-点击【同意并提交信息】-输入验证码-点击【点击提交】。使用范围：巧虎产品页面内。权益有效期：以页面提示为准。权益价值：约合10元巧虎早教体验礼包。使用条件：未参与巧虎体验商品活动、0~3岁宝宝家长，具体使用条件以第三方页面活动规则公示为准。（客服电话：800-820-5099，赠品邮寄周期在15~20个工作日左右。）</p>
<p>26.	【联通王卡限时送会员】领取流程：进入第三方权益展示页面-输入（姓名、手机号、收货地区、详细地址、身份证）-点击【我已经阅读】-点击【免费领取】。开卡成功后首次充值50元及以上话费可关注【运营商小助手】回复【领取会员】可领取1个月腾讯视频VIP会员。使用范围：活动H5页面内。权益有效期：以页面提示为准。权益价值：约合20元腾讯视频VIP会员月卡。使用条件：年满16周岁用户可申领，月租费29元、国内语音通话每分钟0.1元；腾讯视频VIP领取条件：开卡激活需首充50元以上；具体使用条件以第三方页面活动规则公示为准。（客服电话：400-040-1018；号卡退订方式：微信搜索中国联通营业厅小程序-点击【我的订单】-输入手机号码、验证码、身份证号码后六位-点击【立即查询】-选择【取消订单】-点击【申请退单】-选择退单类型-点击【提交】。）</p>
<p>27.	【防疫出行保障免费领】领取流程：进入第三方权益展示页面-输入（手机号、姓名、身份证号）-点击【下一步】（该页面提示为已完成申领免费防疫津贴及出行意外保障。根据页面提示可自愿升级为付费险，若不愿升级可关闭当前页面，不影响免费防疫津贴或出行意外保障的领取。）防疫津贴激活流程：完成申领防疫津贴后-根据手机短信指引激活保障；出行意外保障若申领成功会收到手机短信告知承保结果（无需操作激活）。使用范围：活动H5页面内。权益价值：最高1400元防疫津贴+最高100万元出行意外保障。使用条件：防疫津贴申领条件：年满18~70周岁，申领后7日内根据短信指引激活后生效，若7日内不激活视为放弃保障，保障期限7天；出行意外险申领条件：年满25~50周岁，申领后若成功会以短信形式告知承保结果，保障期限90天；具体使用条件以第三方页面活动规则公示为准。（客服电话：020-37889192）</p>
<p>28.	【新冠隔离津贴免费领】领取流程：进入第三方权益展示页面-输入（手机号、姓名、身份证号）-点击【下一步】（该页面提示为已完成申领新冠隔离津贴及出行意外保障。根据页面提示可自愿升级为付费险，若不愿升级可关闭当前页面，不影响免费隔离津贴或出行意外保障的领取。）新冠隔离津贴激活流程：完成申领新冠隔离津贴后-根据手机短信指引激活保障；出行意外保障若申领成功会收到手机短信告知承保结果（无需操作激活）。使用范围：活动H5页面内。权益价值：最高1400元隔离津贴+最高100万元出行意外保障。使用条件：隔离津贴申领条件：年满18~70周岁，申领后7日内根据短信指引激活后生效，若7日内不激活视为放弃保障，保障期限7天；出行意外险申领条件：年满25~50周岁，申领后若成功会以短信形式告知承保结果，保障期限90天；具体使用条件以第三方页面活动规则公示为准。（客服电话：020-37889192）</p>
<p>29.	【叮当快药新人专享优惠】 领取流程：进入第三方权益展示页面-点击-【允许】获取位置权限-活动页面中选择任意商品并点击-输入手机号验证码-点击-【我已阅读】-点击【注册/登录领取】-点击【立即使用】-点击【提交】-确认收货信息-点击【确认提交】。  使用范围：叮当快药活动页面内。 权益有效期：以页面提示为准。 权益价值：6元起商品价格优惠。使用条件：仅限叮当快药新用户参与使用，部分地区由于库存问题，可能会导致无法换购。具体使用条件以第三方页面活动规则公示为准 （温馨提示：活动页面商品选购后无法修改，请先确认好并选购）。（客服电话：95028）</p>
<p>30.	【移动宝藏卡限时送话费】领取流程：进入第三方权益展示页面-填写领卡信息（姓名、手机号、地区、详细地址、身份证号）-点击【免费领卡】。开卡激活并首充50元及以上-微信关注【有礼小禾】-点击【领取红包】-输入下单宝藏卡手机号-点击【领取红包】-获得1~30元不等额随机话费红包。  使用范围：移动宝藏卡活动页面内。  权益有效期：以页面提示为准。 权益价值：1-30元随机话费红包 。使用条件：年满18～65周岁用户可申领，月租费29元、国内语音通话每分钟0.1元，话费红包领取条件：开卡激活后需首充50元及以上。具体使用条件以第三方页面活动规则公示为准。   （客服电话：19157955699；退订方式：可拨打客服电话进行取消。）</p>
<p>31.	【 美团优选菜场天天低价】  领取流程：进入第三方权益展示页面-点击【立即领取】-点击【允许】打开美团优选小程序-根据页面提示点击【立即领取】-选择页面中所需商品并点击-点击【立即购买】或【加入购物车】-确认收货地址后点击【立即支付】。  使用范围：美团优选小程序内。  权益有效期： 以页面提示为准。  权益价值：八折抢鲜券，最高可抵扣2元。使用条件： 具体使用条件以第三方页面活动规则公示为准（  客服电话：4000-919-699） </p>
<p>32.	【航海王新手礼包】 领取流程：进入第三方权益展示页面-点击【立即下载】-点击右上角【...】-点击使用浏览器打开-点击【立即下载】-安卓用户下载apk包-进入游戏后等级达到6级-点击屏幕左上角头像-点击【系统设置】-点击【礼包兑换】-输入并兑换。 使用范围：航海王页面内。权益有效期： 以页面提示为准。 权益价值： 约合30元新手礼包。使用条件： 角色等级需达到6级；具体使用条件以第三方页面活动规则公示为准。  （在线QQ客服：609513206；客服在线时间：09:00-19：00） </p>
<p>33.	【天天开开乐盲盒代金券】领取流程：进入第三方权益展示页面-点击【立即开启】-点击【打开】进入天天开开乐小程序-点击【开启盲盒】-选择【潮男】或【潮女】-点击我已阅读-点击【登录】-点击【允许】获取昵称、头像-点击【开启盲盒】-点击【确认支付】。使用范围 ：天天开开乐小程序内。权益有效期： 以页面提示为准 。权益价值：9.95元优惠券 。使用条件：具体使用条件以第三方页面活动规则公示为准。 （客服电话：13386505741）</p>
<p>34.	【百草味89元任选15件 】领取流程： 进入第三方权益展示页面-点击【89元任选15件】-点击【允许】打开百草味官方商城-选择活动页面中指定商品并点【立即购买】或【加入购物车】-点击【允许】获取你的昵称头像-勾选并点击【同意并继续】-点击【微信快捷登录】-点击【允许】获取你的手机号-点击【我知道了】-返回活动页面选择商品后点击【立即购买】-确认收货地址后-点击【提交订单】 使用范围：百草味官方商城小程序内。权益有效期：以页面提示为准。 权益价值：89元任选15件商品。使用条件：具体使用条件以第三方页面活动规则公示为准 。（客服电话：4008-810-118 客服工作时间：09:00-18:00） </p>
<p>35.	【无尽战神新手礼包】 领取流程：进入第三方权益展示页面-点击【下载游戏】-点击右上角【...】使用浏览器打开-点击【下载游戏】-安卓用户下载apk包-进入游戏后等级达到40级点击【主页】-【福利】-输入礼包码即可领取。使用范围：无尽战神App内。权益有效期： 以页面提示为准。权益价值： 约合30元新手礼包。使用条件：仅限安卓用户，具体使用条件以第三方页面活动规则公示为准。（客服电话：020-38100135或微信搜索公众号“冰橙游戏”联系在线客服, 客服工作时间：9:00-21:00）</p>
<p>36.	【防疫出行门诊保障】领取流程：进入第三方权益展示页面-输入（手机号、姓名、身份证号）-点击【下一步】（该页面提示为已完成申领免费防疫津贴+出行意外保障+门诊补贴，领取结果将以短信形式告知。根据页面提示可自愿升级为付费险，若不愿升级可关闭当前页面，不影响免费防疫津贴或出行意外保障或门诊补贴的领取。）防疫津贴及门诊补贴激活流程：完成申领防疫津贴后-根据手机短信指引激活保障；出行意外保障若申领成功会收到手机短信告知承保结果。使用范围：防疫出行门诊保障页面内。权益有效期：以页面提示为准。权益价值：免费领取最高100万出行意外险、1400元防疫津贴及最高100元门诊补贴。防疫津贴申领条件：年满18~70周岁，申领后7日内根据短信指引激活后生效；出行意外险申领条件：年满25~50周岁，申领结果以短信通知为准；门诊补贴申领条件：年满18~65周岁，申请后7日内根据短信指引激活后生效；使用条件：具体使用条件以第三方页面活动规则公示为准。 （客服电话：020-37889192，客服时间：工作日10:00-17:00。） </p>
<p>37.	【神奇特权话费满减券】领取流程：进入第三方权益展示页面-输入手机号-点击【立即抢购】-确认并支付-支付成功后收到神奇特权短信-根据短信内容点击链接并下载神奇特权（苹果用户下载神奇特权App，安卓用户下载神奇卡App）-跳转兑换页-输入充值手机号、验证码-点击同意神奇卡用户隐私协议-点击【登录领取】-点击【好的】。  使用范围：神奇特权H5页面内。权益有效期：以页面提示为准。权益价值：约合100元话费优惠券包。使用条件：优惠券每张充值满100元可用，可抵扣5元；具体使用条件以第三方页面活动规则公示为准。 （客服电话：400-966-8012）</p>
<p>38.	【良品铺子满减优惠券】 领取流程：进入第三方权益展示页面-点击【立即领取】-点击【立即领取】-点击【允许】获取昵称头像手机号-点击【立即使用】-选择活动页面中指定商品-点击【立即购买】或【加入购物车】-确认商品规格-点击【下一步】-确认收货地址-点击【微信支付】。  使用范围：良品铺子H5页面内。权益有效期：以页面提示为准。权益价值：良品铺子满198减130优惠券。使用条件：具体使用条件以第三方页面活动规则公示为准。（客服电话：4001177517，客服时间：周一到周日09:00-22:00；查看订单及优惠券方式：关注【良品铺子美食旅行】微信公众号-点击【良粉中心】-【查询订单】可查看优惠券及订单信息。）</p>
<p>39.	【视频会员限时折扣】 领取流程：进入第三方权益展示页面-选择页面中视频会员-点击【立即抢购】-点击【立即购买】-输入会员手机号-点击【提交订单】-点击【确认】。使用范围：视频会员H5页面内。权益有效期：以页面提示为准。权益价值：约合3元视频会员支付优惠。使用条件：具体使用条件以第三方页面活动规则公示为准。（企业微信客服：xiaozhutaozai，客服工作时间：工作日09:30-18:30。） </p>
<p>40.	【拼多多20元补贴券】领取流程：进入第三方权益展示页面-选择页面中活动商品-点击【立即抢购】-点击【立即抢购】-点击【立即购买】-选择商品规格-确认收货地址-点击【立即支付】 使用范围：拼多多H5页面内。权益有效期：以页面提示为准。权益价值：购物优惠20元。使用条件： 具体使用条件以第三方页面活动规则公示为准。（客服电话：021-53395288）</p>
<p>41.	【满一营养品限时优惠】领取流程：进入第三方权益展示页面-点击【立即选购】-点击【允许】进入满一优品小程序-选择对应专区商品-点击【立即购买】或【加入购物车】-选择产品规格-点击【立即购买】-点击【立即登录】-点击【允许】授权微信昵称、头像-点击【确认】授权绑定手机号-点击【允许】-点击【提交】。使用范围：满一优品小程序内。权益价值：约合30元营养品价格优惠，专区内商品实付39.9元。使用条件：受疫情影响，部分地区暂时无法发货；具体使用条件以第三方页面活动规则公示为准。（客服电话：0571-85099006，工作时间：工作日9：00-20：00。）</p>
<p>42.	【泰康出行意外险免费领】领取流程：进入第三方权益展示页面-输入（手机号码、验证码、姓名、身份证号）-点击【免费领取】。（该页面提示为已完成意外保障领取，根据页面提示可自愿升级为付费险，若不愿升级可关闭当前页面，不影响意外保障的领取）意外保障若申领成功会收到手机短信告知承保结果。 使用范围：泰康赠险H5页面内。权益有效期：以页面提示为准。权益价值：最高100万元出行意外保障。使用条件条件：年满22-50周岁。（客服电话：010-80932272；工作时间：工作日10:00-17:00。）  </p>
<p>43.	【移动宝藏卡话费红包】领取流程：进入第三方权益展示页面-输入（姓名、电话号码、身份证、所在地区、详细地址、选择号码）-点击【我已阅读并同意】-开卡激活并首充50元后-微信关注【号卡小助手吖】-点击【特惠福利】-点击【1-30元话费红包】-输入已领取手机号并填写短信验证码-点击【抽奖】。使用范围： 宝藏卡H5页面内。权益有效期：以页面提示为准 。权益价值：价值1-30元不等话费红包； 使用条件：月租费29元、国内语音通话每分钟0.1元；需开卡激活并首充50元；具体使用条件以第三方页面活动规则公示为准。 （客服电话：15279507574；客服工作时间：09:00-17:00。）  </p>
<p>44.	【视频会员限时优惠】领取流程：进入第三方权益展示页面-选择视频种类-点击【立即充值】-输入充值号码-点击【确认购买】-点击【允许】获取昵称头像-点击【确认购买】-点击【确认无误】。使用范围： 视频会员H5页面内。权益有效期：以页面提示为准。权益价值：约合3元视频会员支付优惠。使用条件：具体使用条件以第三方页面活动规则公示为准。（ 客服电话：028-83546764；工作时间：9:00-18:00。） </p>
<p>45.	【蓝月亮限时优惠】领取流程：进入第三方权益展示页面-点击【点击抢购】-点击【允许】打开蓝月亮直营商城小程序-点击【立即抢购】-选择页面中指定商品并点击-点击【加入购物车】或【立即购买】-选择商品规格-点击【下一步】-点击【允许】-阅读协议信息-点击【允许】获取手机号-确认收货地址-点击【微信支付】。使用范围： 蓝月亮直营商城小程序内。权益有效期： 以页面提示为准。 权益价值：26.9元任选三件限时优惠。使用条件：具体使用条件以第三方页面活动规则公示为准。（客服电话：13418079678，工作时间：09:00-18:00。）</p>
<p>46.	【出行重疾保障免费领】领取流程：进入第三方权益展示页面-输入（手机号、姓名、身份证）点击【激活保障】（该页面为已成功申领出行保障+重疾保障，根据页面提示可升级为付费险，若不升级可关闭当前页面，不影响出行保障+重疾保障的领取。）重疾保障及出行保障承保结果以短信通知为准。使用范围： 出行重疾保障页面内。权益有效期：以页面提示为准。  权益价值：最高100万出行保障+68000元重疾保险。使用条件：重疾保障领取年龄：18-70周岁；交通意外保障领取年龄：25-50周岁；具体使用条件以第三方页面活动规则公示为准。（客服电话：020-37889192；工作时间：10:00-17:30）</p>
  </div>
</template>

<script>
export default {
  name: 'BaseActivityRules',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.m-rule-cont {
  font-size: 24px;
  line-height: 36px;
  color: #2E2E2E;
  padding-bottom:30px;
  h3 {
    margin-bottom: 5px;
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    padding-top:47px;
    padding-bottom:20px;
  }
  h4 {
    font-weight: bold;
  }
  b {
    display: block;
    margin-top: 6px;
  }
}
</style>
