import axios from 'axios'
import { Toast } from 'vant'
import { imgPathAddDomain } from '@/utils/index.js'
import Qs from 'qs'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  //baseURL: 'http://rap2api.taobao.org/app/mock/294648',
  withCredentials: true,
  crossDomain: true,
  timeout: 30000
})
// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers.token = localStorage.getItem('token')
    config.headers['Content-Type'] =
      'application/x-www-form-urlencoded;charset=UTF-8'
    config.data = Qs.stringify(config.data)
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    // return Promise.reject(error);
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // if the custom code is not 100, it is judged as an error.
    console.log(res)
    if (res.code != 200) {
      Toast({
        message: res.msg,
        forbidClick: true
      })
      return Promise.reject(res)
    } else if (res.code == 900) {
      Toast({
        message: '登录状态超时，请重新扫码进入',
        forbidClick: true
      })
    } else {
      if (response.config.imgPathAddDomain) {
        return imgPathAddDomain(res)
      } else {
        return res
      }
    }
  },
  error => {
    console.log('err' + error) // for debug
    Toast({
      message: '服务器升级中，请稍后重试',
      forbidClick: true
    })
    return Promise.reject(error)
  }
)
export default service
